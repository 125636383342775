/* Table of Contents
/* ------------------------------------------------------------

This is a development CSS file which is built to a minified
production stylesheet in assets/built/screen.css

12. Pagination
13. Code
14. Home

*/

/* 12. Pagination - Tools to let you flick between pages
/* ---------------------------------------------------------- */

@import "global.css";

/* The main wrapper for our pagination links */
.pagination {
    position: relative;
    width: 80%;
    max-width: 800px;
    margin: 4rem auto;
    font-size: 1.3rem;
    color: #9EABB3;
    text-align: center;
}

.pagination a {
    color: var(--blue);
    transition: all 0.2s ease;
}

/* Push the previous/next links out to the left/right */
.older-posts,
.newer-posts {
    position: absolute;
    display: inline-block;
    padding: 0 15px;
    border: #bfc8cd 1px solid;
    text-decoration: none;
    border-radius: 4px;
    transition: border 0.3s ease;
}

.older-posts {
    right: 0;
}

.page-number {
    display: inline-block;
    padding: 2px 0;
    min-width: 100px;
}

.newer-posts {
    left: 0;
}

.older-posts:hover,
.newer-posts:hover {
    color: var(--blue);
    border-color: #98a0a4;
}

.extra-pagination {
    display: none;
    border-bottom: #EBF2F6 1px solid;
}
.extra-pagination:after {
    display: block;
    content: "";
    width: 7px;
    height: 7px;
    border: #E7EEF2 1px solid;
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    background: #FFF;
    border-radius: 100%;
    box-shadow: #FFF 0 0 0 5px;
}
.extra-pagination .pagination {
    width: auto;
}

/* On page2+ make all the headers smaller */
.paged .main-header {
    max-height: 30vh;
}

/* On page2+ show extra pagination controls at the top of post list */
.paged .extra-pagination {
    display: block;
}

/* Every post, on every page, gets this style on its <article> tag */
.pagination {
    padding-top: 4rem;
    border-top: #bfc8cd 1px solid;
    word-wrap: break-word;
}

/* Add a little circle in the middle of the border-bottom on our .post
   just for the lolz and stylepoints. */
.pagination:before {
    display: block;
    content: "";
    width: 7px;
    height: 7px;
    border: #bfc8cd 1px solid;
    position: absolute;
    top: -5px;
    left: 50%;
    margin-left: -5px;
    background: #f4f8fb;
    border-radius: 100%;
    box-shadow: #f4f8fb 0 0 0 5px;
}

figure.highlight,
.highlight pre,
.highlight code,
.highlight table,
.highlight tbody,
.highlight tr,
td.code {
    border: none;
    min-width: 100%;
    max-width: 100%;
    font-family: var(--codeFonts);
    line-height: 1.75em;
    color: color(var(--darkgrey));
}

.highlight table,
.highlight td pre {
    padding: 0;
    margin: 0;
}

.highlight table td {
    border: none;
    margin: none;
    padding: none;
}

.highlight table td:first-child,
.highlight table td:last-child {
    background: none;
}

/* 13. Code - Inlined code, code boxes with or without line numbers
/* ---------------------------------------------------------- */

code table, 
code table td, 
code table th, 
code table tbody, 
code table tr,
td.gutter pre {
    padding: 0;
    border: none;
    background-color: var(--lightBlueGrey);
}

td.code pre {
    border: none;
}

td.code {
    padding: 0;
}

.highlight pre {
    background-color: var(--lightBlueGrey); 
    border: 1px solid #d9d9d9; 
    padding: 6px 10px;
    border-radius: 6px;
    margin: 1em 0 1em;
}

.highlighter-rouge {
    min-width: 100%;
    max-width: 100%;
}

.gist {
    min-width: 100%;
    max-width: 100%;
}

/* Make line numbers unselectable: excludes line numbers from copy-paste user ops */
.highlight .lineno { 
    color: rgba(0,0,0,0.3);
    -webkit-user-select: none;
    -moz-user-select: none; 
    -o-user-select: none; 
}

/* Mozilla specific */
.lineno::-moz-selection {
    background-color: transparent;
} 

/* Other major browsers */
.lineno::selection {
    background-color: transparent;
} 

table.rouge-table {
    background: none;
}

.MathJax_Display {
    color: var(--darkgrey);
}

/* 14. Home
/* ---------------------------------------------------------- */

.site-header {
    background: #fff;
    border-bottom: 1px solid #e1e1e1;
}

.site-header-content {
    color: var(--darkgrey);
}

.nav li a {
    color: var(--darkgrey);
    font-weight: bold;
    opacity: 1;
    font-size: 1.1em;
}

.nav li a:hover {
    color: var(--blue);
}

.social-link {
    color: var(--darkgrey);
}

.social-link svg {
    fill: var(--darkgrey);
}

.social-link svg:hover {
    fill: var(--blue);
}

.site-description {
    font-family: var(--primaryFonts);
    font-size: 1.4em;
}

@media (min-width: 900px) {
    .home-template .post-feed,
    .tag-template .post-feed,
    .author-template .post-feed {
        margin-top: 30px;
    }
    .home-template .site-nav {
        top: 0;
    }
}

@media (min-width: 900px) {
    .site-nav-container {
        padding: 0;
    }
}

.site-header-content {
    padding: 3vw 1.5vw;
}

.site-header:before {
    background: #fff;
}

.home-template {
    /* background-color: var(--lightBlueGrey); */
}

.site-logo {
    max-height: 100px;
}

@media (min-width: 900px) {
    .post-card.home-template.no-image {
        flex-grow: 0;
    }
}

@media screen and (min-width: 0px) and (max-width: 900px) {
    .social-link-fb, .site-footer-twitter, .site-footer-email { 
        display: none; 
    }
}

.read-next {
    background: var(--lightBlueGrey);
}

/* Table */

.post-full-content table {
    color: var(--darkgrey);
}

.post-full-content table code {
    font-size: 0.9em;
}

.post-full-content table th {
    font-size: 0.9em;
}

.kg-card-markdown {
    display: block;
}

/* Call to action */

.call-to-action hr {
    margin: 1vw 0;
}

/* Carbon */

#carbonads {
    display: flex;
    float: right;
    margin: 0 0 20px 20px;
    max-width: 130px;
    border-radius: 4px;
    background-color: #fafafa;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
    font-size: 11px;
    font-family: var(--primaryFonts);
}

#carbonads a {
    color: #111;
    text-decoration: none; 
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
}

#carbonads a:hover {
    color: #111; 
}

.carbon-img {
    display: block;
    margin-bottom: 8px;
    max-width: 130px;
    line-height: 1; 
}

.carbon-img img {
    display: block;
    margin: 0 auto;
    max-width: 130px;
    width: 130px;
    height: auto; 
}

.carbon-text {
    display: block;
    padding: 0 10px 8px;
    text-align: left;
    line-height: 1.35; 
}

.carbon-poweredby {
    display: block;
    padding: 10px;
    background: repeating-linear-gradient(-45deg, transparent, transparent 5px, rgba(0, 0, 0, 0.025) 5px, rgba(0, 0, 0, 0.025) 10px) rgba(241, 243, 244, 0.4);
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 600;
    font-size: 8px;
    line-height: 0; 
}

@media only screen and (min-width: 320px) and (max-width: 759px) {
    #carbonads {
        position: relative;
        float: none;
        margin: 20px 0;
        max-width: 330px; 
    }
    .carbon-wrap {
        display: flex;
        flex-direction: row; 
    }
    .carbon-img {
        margin: 0; 
    }
    .carbon-text {
        padding: 10px 10px 0 10px;
        font-size: 12px; 
    }
    .carbon-poweredby {
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 0;
        border-top-left-radius: 3px;
        text-align: center; 
    } 
}